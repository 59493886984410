import classNames from "classnames"
import { Img } from "../"
import React from "react"
import { Appear } from ".."
import { Image } from "../../models/Image"
import { Section } from "../../models/Section"

export const ImageSection = (props: Image & Section) => {
  const { caption, file, fullWidth, isBlog, order } = props

  if (fullWidth) {
    return (
      <Appear order={order}>
        <div style={{ maxWidth: "1560px" }} className="mx-auto">
          <Img image={file.gatsbyImageData} alt={file.alt ?? ""} />
          {caption && <div className="container paragraph-s mt-2">{caption}</div>}
        </div>
      </Appear>
    )
  }

  return (
    <div className="container">
      <div className="flex justify-center lg:-mx-3">
        <div className={classNames("w-full lg:px-3", isBlog ? "md:w-5/6 lg:w-2/3" : "lg:w-1/2")}>
          <Appear order={order}>
            <Img image={file.gatsbyImageData} alt={file.alt ?? ""} />
          </Appear>
          {caption && <div className="paragraph-s  mt-2">{caption}</div>}
        </div>
      </div>
    </div>
  )
}
