import React from "react"
import { Appear, Button } from "../"
import { useLayout } from "../../context"

export const LetsTalk = () => {
  const { isWhiteBackground } = useLayout()

  return (
    <section className="container pb-16 lg:pb-20">
      <div className="flex flex-wrap lg:-mx-3">
        <div className="w-full lg:w-1/4 lg:px-3">
          <Appear order={50}>
            <h3 className="heading-l lg:heading-xl mb-5">Let’s talk</h3>
          </Appear>
        </div>
        <div className="w-full md:w-3/4 lg:w-1/2 lg:px-3 xl:w-2/5">
          <Appear order={55}>
            <p className="paragraph-s md:paragraph-l mb-10">
              We’re always open for a chat, so get in touch to&nbsp;find out how
              we can help
            </p>
            <Button to="/contact" isWhite={!isWhiteBackground}>
              Get in touch
            </Button>
          </Appear>
        </div>
      </div>
    </section>
  )
}
