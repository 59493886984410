import React from "react"
import { Appear, Link, SocialIcons } from "../"
import { FooterStudios } from "./FooterStudios"

export const Footer = () => {
  return (
    <>
      <FooterStudios />
      <Appear order={100} distance="0">
        <footer className="container py-4 md:py-6">
          <div className="flex justify-between pb-2">
            <div className="paragraph-s md:py-1">
              &copy; 2020 DesignStudio Group Ltd.
              <ul className="md:inline-block">
                <li className="inline-block md:ml-6">
                  <Link
                    to="/privacy-policy"
                    className="underline hover:opacity-70"
                  >
                    Privacy
                  </Link>
                </li>
                <li className="inline-block ml-2 md:ml-6">
                  <Link
                    to="/terms-of-use"
                    className="underline hover:opacity-70"
                  >
                    Terms of use
                  </Link>
                </li>
              </ul>
            </div>
            <div className="hidden md:block">
              <SocialIcons />
            </div>
          </div>
          <div style={{ fontSize: "12px" }}>
            A private limited company incorporated in England and Wales under
            company number 10063271. Registered at 3 Dufferin Street, London,
            EC1Y 8NA
          </div>
        </footer>
      </Appear>
    </>
  )
}
