import { AnimationSegment } from "lottie-web"

export enum LogoTransitionType {
  JumpOutUp = "Jump Out up",
  JumpInDown = "Jump In Down",
  JumpUpAndDown = "Jump Up & Down",
  ThrowLettersUp = "Throw Letters Up",
  JumpAndSpin = "Jump & Spin",
  Sad = "Sad",
  Smile = "Smile",
  Wiggle = "Wiggle",
}

export const getSegment = (
  transitionType: LogoTransitionType
): AnimationSegment => {
  // Animation has 50fps
  switch (transitionType) {
    case LogoTransitionType.JumpOutUp:
      return [0, 50]
    case LogoTransitionType.JumpInDown:
      return [100, 150]
    case LogoTransitionType.JumpUpAndDown:
      return [150, 200]
    case LogoTransitionType.ThrowLettersUp:
      return [300, 350]
    case LogoTransitionType.JumpAndSpin:
      return [450, 500]
    case LogoTransitionType.Smile:
      return [600, 750]
    case LogoTransitionType.Wiggle:
      return [750, 850]
    case LogoTransitionType.Sad:
      return [900, 1000]
    default:
      return [0, 0]
  }
}
