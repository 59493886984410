module.exports = {
  purge: ["./src/**/*.jsx", "./src/**/*.js", "./src/**/*.tsx", "./src/**/*.ts"],

  theme: {
    colors: {
      black: {
        DEFAULT: "#000",
        border: "rgba(0,0,0,0.1)",
      },
      white: "#fff",
      grey: {
        DEFAULT: "#808080",
        light: "#F7F7F7",
        mid: "#B3B3B3",
      },
      transparent: "transparent",
    },

    container: {
      center: true,
      padding: "1.25rem",
    },

    fontFamily: {
      sans: ["Universal Sans", "system-ui", "sans-serif"],
    },

    fontWeight: {
      normal: 550,
      semibold: 850,
    },

    fontSize: {
      16: "1rem",
      20: "1.25rem",
      26: "1.625rem",
      34: "2.125rem",
      48: "3rem",
    },

    lineHeight: {
      none: "1",
      24: "1.5rem",
      28: "1.75rem",
      38: "2.375rem",
      40: "2.5rem",
      56: "3.5rem",
    },

    screens: {
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      xxl: "1600px",
    },

    extend: {
      opacity: {
        10: 0.1,
        30: 0.3,
        70: 0.7,
      },
      transitionTimingFunction: {
        "ease-in-expo": "cubic-bezier(0.785, 0.135, 0.15, 0.86)",
      },
    },
  },

  variants: {
    margin: ["responsive", "last"],
    padding: ["responsive", "last"],
    opacity: ["responsive", "hover", "focus", "group-hover"],
  },

  plugins: [],
}
