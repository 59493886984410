import { GatsbyLinkProps } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import React from "react"
import { useLayout } from "../../context"
import { Locale } from "../../models/Locale"
import { LogoTransitionType } from "../Logo"

interface Props {
  locale?: Locale
}

export const Link = (props: Props & GatsbyLinkProps<true>) => {
  const { locale: currentLocale, startLogoTransition } = useLayout()
  const { locale, to, ...linkProps } = props

  let toWithLocale = to

  if (locale) {
    toWithLocale = locale === Locale.English ? to : `/${locale}${to}`
  } else if (currentLocale && currentLocale != Locale.English) {
    toWithLocale = `/${currentLocale}${to}`
  }

  return (
    <TransitionLink
      exit={{
        length: 0.75,
        trigger: ({ node, e }: any) => {
          startLogoTransition(LogoTransitionType.JumpAndSpin)
          node.style.opacity = 0
        },
      }}
      entry={{
        delay: 0.75,
        length: 0.75,
        trigger: ({ node, e }: any) => {
          window.scrollTo(0, 0)
          startLogoTransition(LogoTransitionType.Smile)
        },
      }}
      to={toWithLocale}
      {...linkProps}
    />
  )
}
