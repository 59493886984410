import React, { ReactElement, useRef } from "react"
import { SlideItemImage } from "./SlideItemImage"
import { SlideItemVideo } from "./SlideItemVideo"
import { Slide } from "../../models/Slide"
import { Link } from ".."
import { useOnScreen } from "../../hooks"
import { useLayout } from "../../context"

type Props = Omit<Slide, "caption" | "key"> & {
  isActive: boolean
  onVideoEnded?: () => void
}

export const SlideItem = (props: Props) => {
  const { image, isActive, onVideoEnded, url, video } = props
  const ref = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(ref)
  const { assetLink } = useLayout()

  let slide: ReactElement = <></>

  if (video) {
    slide = (
      <div ref={ref}>
        <SlideItemVideo
          video={assetLink(video)}
          image={assetLink(image)}
          isPlaying={isActive || (onScreen && !onVideoEnded)}
          onEnded={onVideoEnded}
        />
      </div>
    )
  } else if (image) {
    slide = <SlideItemImage image={image} />
  }

  if (url) {
    slide = <Link to={url}>{slide}</Link>
  }

  return slide
}
