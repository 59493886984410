import { useEffect, useState } from "react"
import { theme } from "../../tailwind.config"

const debounce = (delay: number, fn: any) => {
  let timerId: any

  return (...args: any[]) => {
    if (timerId) {
      clearTimeout(timerId)
    }

    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

export const useWindowSize = (debounceTime?: number) => {
  const isClient: boolean = typeof window !== `undefined`
  const getSize = () => ({
    height: isClient ? window.innerHeight : undefined,
    isDesktop: isClient && window.innerWidth >= parseInt(theme.screens.lg, 10),
    isMobile: isClient && window.innerWidth < parseInt(theme.screens.md, 10),
    hasMouse: isClient && !window.matchMedia("(any-hover: none)").matches,
    width: isClient ? window.innerWidth : undefined,
  })
  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    if (!isClient) {
      return
    }

    const handleResize = () => {
      setWindowSize(getSize())
    }

    let handleResizeFn = handleResize

    if (debounceTime) {
      handleResizeFn = debounce(debounceTime, handleResize)
    }

    window.addEventListener("resize", handleResizeFn)
    return () => window.removeEventListener("resize", handleResizeFn)
  }, [])

  return windowSize
}
