import queryString from "query-string"
import { BlogItem } from "./models/BlogItem"
import { CaseStudy } from "./models/CaseStudy"
import { GeoData } from "./models/GeoData"
import { Locale } from "./models/Locale"
import { VideoFile } from "./models/VideoFile"

type ContentWithTags = CaseStudy | BlogItem

export const getUniqueTags = (items: ContentWithTags[], allLabel: string) => {
  const tags = items
    .filter((item) => item.tags)
    .reduce((all, item) => {
      JSON.parse(item.tags!).forEach((tag: string) => {
        if (all.findIndex((t) => t.toLowerCase() === tag.toLowerCase()) === -1) {
          all.push(tag.replace(/\b\w/g, (c) => c.toUpperCase()))
        }
      })
      return all
    }, new Array<string>())
    .sort()

  tags.unshift(allLabel)
  return tags
}

export const getVisitorGeoData = async (): Promise<GeoData> => {
  const geoParams = queryString.parse(location.search)
  if (typeof geoParams.country === "string" && typeof geoParams.continent === "string") {
    return {
      country_code: geoParams.country.toUpperCase(),
      continent_code: geoParams.continent.toUpperCase(),
    }
  }

  try {
    const response = await fetch("/.netlify/functions/geo")
    return await response.json()
  } catch (e) {
    return { country_code: "", continent_code: "" }
  }
}

export function groupBy<T extends any>(array: T[], keyFn: (obj: T) => string): Record<string, T[]> {
  return array.reduce((objectsByKeyValue, obj) => {
    const value = keyFn(obj)
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {} as Record<string, T[]>)
}

export function replaceAssetsDomain(object: any, locale: Locale) {
  if (!object || locale !== Locale.Chinese) {
    return object
  }

  return JSON.parse(
    JSON.stringify(object)
      .replace(/www\.datocms\-assets\.com/g, "assets.design.studio")
      .replace(/stream\.mux\.com/g, "assets.design.studio")
      .replace(/image\.mux\.com/g, "assets.design.studio/thm")
  )
}

export function getFullVideo(video: VideoFile, locale: Locale) {
  return locale === Locale.Chinese ? video.video?.mp4UrlMedium : video.video?.streamingUrl
}
