import React from "react"
import { Appear, CaseStudyItem } from ".."
import { CaseStudy } from "../../models/CaseStudy"

interface Props {
  items?: CaseStudy[]
}

export const RelatedCaseStudies = (props: Props) => {
  const { items } = props

  if (!items || items.length === 0) {
    return null
  }

  return (
    <section className="container px-0 md:px-5 mt-4 lg:mt-0 pb-8 lg:pb-12">
      <h3 className="heading-m lg:heading-l pl-5 md:pl-0">
        <Appear order={50}>More work</Appear>
      </h3>

      <div className="mt-6 lg:mt-8 flex flex-wrap md:-mx-1">
        {items.map((item, i) => (
          <div key={item.id} className="w-full mb-8 md:w-1/2 md:px-1">
            <Appear order={i + 51}>
              <CaseStudyItem key={item.id} {...item} />
            </Appear>
          </div>
        ))}
      </div>
    </section>
  )
}
