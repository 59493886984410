import React, { useState } from "react"
import { ImageGalleryModal, Img } from "../"
import { Studio } from "../../models/Studio"

interface Props {
  studio: Studio
}

export const StudioDescription = (props: Props) => {
  const { description, photos, region } = props.studio
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)

  return (
    <>
      <div onClick={() => setIsOpen(true)} className={photos.length >= 2 ? "cursor-pointer" : ""}>
        <Img image={photos[index].gatsbyImageData} alt={photos[index].alt || ""} />
      </div>
      {photos.length >= 2 && (
        <ImageGalleryModal
          caption={region}
          isOpen={isOpen}
          onSlideChanged={setIndex}
          photos={photos}
          setIsOpen={setIsOpen}
          initialSlide={index}
        />
      )}
      <div className="paragraph-s opacity-50 mt-2">
        {index + 1} / {photos.length}
      </div>
      <h3 className="heading-m my-2 lg:heading-l lg:mb-4">{region}</h3>
      <p className="paragraph-s">{description}</p>
    </>
  )
}
