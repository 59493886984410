import { motion } from "framer-motion"
import React, { useMemo } from "react"
import { Link, Overlay, SocialIcons } from "../"
import { MenuLink } from "../../models/MenuLink"

const menuItem = {
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
    x: -20,
  },
  open: (key: number) => ({
    opacity: 1,
    transition: {
      delay: key * 0.075,
      ease: [0.785, 0.135, 0.15, 0.86],
      type: "tween",
    },
    x: 0,
  }),
}

const socialIcons = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      delay: 0.75,
      duration: 0.5,
    },
  },
}

interface Props {
  isOpen: boolean
  menuLinks: MenuLink[]
  setIsOpen: (isOpen: boolean) => void
}

export const MenuMobile = (props: Props) => {
  const { menuLinks, isOpen, setIsOpen } = props

  const animate = useMemo(() => (isOpen ? "open" : "closed"), [isOpen])

  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="container flex flex-col justify-between text-white">
        <ul className="mt-16">
          {menuLinks.map((link, key) => (
            <motion.li
              animate={animate}
              custom={key}
              key={`menuMobileLink_${key}`}
              variants={menuItem}
            >
              <Link
                className="font-semibold text-34"
                activeClassName="opacity-70"
                partiallyActive={true}
                to={link.to}
                onClick={() => setIsOpen(false)}
              >
                {link.name}
              </Link>
            </motion.li>
          ))}
        </ul>

        <motion.div animate={animate} variants={socialIcons} className="py-5">
          <SocialIcons />
        </motion.div>
      </div>
    </Overlay>
  )
}
