import React from "react"
import { Img } from ".."
import { ImageFile } from "../../models/ImageFile"

interface SlideItemImageProps {
  image: ImageFile
}

export const SlideItemImage = (props: SlideItemImageProps) => {
  const { image } = props
  return <Img alt={image.alt ?? ""} image={image.gatsbyImageData} key={image.url} />
}
