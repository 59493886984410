export interface GeoData {
  country_code: string
  continent_code: string
}

// https://dev.maxmind.com/geoip/legacy/codes/iso3166/
export enum Country {
  Default = "",
  Australia = "AU",
  China = "CN",
  France = "FR",
  GreatBritain = "GB",
  India = "IN",
  Poland = "PL",
  Spain = "ES",
  USA = "US",
}

export enum Continent {
  Africa = "AF",
  Antarctica = "AN",
  Asia = "AS",
  Europe = "EU",
  NorthAmerica = "NA",
  Oceania = "OC",
  SouthAmerica = "SA",
}
