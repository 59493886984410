import React, { useMemo } from "react"
import { BlogItem as BlogItemModel } from "../../models/BlogItem"
import { motion } from "framer-motion"
import { Appear } from ".."
import { BlogItem } from "./BlogItem"

interface Props {
  items: BlogItemModel[]
  category: string
  // tag: string
}

export const BlogItems: any = (props: Props) => {
  const { items, category } = props

  // const filteredItems = useMemo(
  //   () =>
  //     tag === "All"
  //       ? items
  //       : items.filter(item => item.tags && item.tags.indexOf(tag) !== -1),
  //   [tag]
  // )

  const filteredItems = useMemo(
    () =>
      category === "All"
        ? items
        : items.filter(
            (item) => item.category && item.category.name === category
          ),
    [category]
  )

  return (
    <div className="flex flex-wrap md:-mx-3">
      {filteredItems.map((item, key) => (
        <motion.div
          key={item.id}
          className={`w-full mb-10 md:w-1/2 lg:w-1/3 md:px-3`}
          layout
          transition={{ type: "tween" }}
        >
          <Appear order={5 + key}>
            <BlogItem {...item} />
          </Appear>
        </motion.div>
      ))}
    </div>
  )
}
