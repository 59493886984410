import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Instagram from "../../svg/instagram.svg"
import Linkedin from "../../svg/linkedin.svg"
import Twitter from "../../svg/twitter.svg"

export const SocialIcons = () => {
  const {
    site: {
      siteMetadata: { socialLinks },
    },
  } = useStaticQuery(graphql`
    query SocialLinksQuery {
      site {
        siteMetadata {
          socialLinks {
            instagram
            linkedin
            twitter
          }
        }
      }
    }
  `)

  return (
    <ul>
      <li className="inline-block">
        <a
          href={socialLinks.instagram}
          target="_blank"
          rel="noopener"
          className="inline-block hover:opacity-70"
          aria-label="Instagram"
        >
          <Instagram className="w-6 h-6 fill-current" />
        </a>
      </li>
      <li className="inline-block ml-6">
        <a
          href={socialLinks.twitter}
          target="_blank"
          rel="noopener"
          className="inline-block hover:opacity-70"
          aria-label="Twitter"
        >
          <Twitter className="w-6 h-6 fill-current" />
        </a>
      </li>
      <li className="inline-block ml-6">
        <a
          href={socialLinks.linkedin}
          target="_blank"
          rel="noopener"
          className="inline-block hover:opacity-70"
          aria-label="LinkedIn"
        >
          <Linkedin className="w-6 h-6 fill-current" />
        </a>
      </li>
    </ul>
  )
}
