import React, { PropsWithChildren, useEffect, useRef, useState } from "react"
// tslint:disable-next-line: no-submodule-imports
import Transition from "react-transition-group/Transition"
import { useAppear } from "../../context"
import { useOnScreen } from "../../hooks"

interface Props {
  distance?: string
  extraDelay?: number
  hideOnExit?: boolean
  order?: number
  rootMargin?: string
  threshold?: number
  timeout?: number
}

export const Appear = (props: PropsWithChildren<Props>) => {
  const {
    distance = "20px",
    extraDelay = 0,
    hideOnExit = false,
    order = 1,
    rootMargin = "-100px 0px 25px",
    threshold = 0,
    timeout = 300,
  } = props

  const { cleanTransitions, getDelay, registerTransition, time } = useAppear()
  const [transitionState, setTransitionState] = useState(false)
  const [transitionDelay, setTransitionDelay] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(ref, rootMargin, threshold, !hideOnExit)

  const transitionStyles = {
    entered: { opacity: 1 },
    entering: { opacity: 1 },
    exited: { opacity: 0, transform: `translateY(${distance})` },
    exiting: { opacity: 0 },
    unmounted: { opacity: 1 },
  }

  useEffect(() => {
    if (onScreen === false) {
      // Hide content only on initial load or if exit param has been set to true
      if (hideOnExit === true || time === -1) {
        setTransitionState(false)
      }
    } else {
      if (transitionState === false) {
        // Transitions staggering effect
        registerTransition(order)
      }
    }
    return () => cleanTransitions()
  }, [onScreen])

  useEffect(() => {
    // Delay transitions only when element appears
    if (!onScreen || transitionState === true) {
      return
    }
    setTransitionDelay(getDelay(order) * 75 + extraDelay)
    setTransitionState(true)

    return () => cleanTransitions()
  }, [time])

  return (
    <div ref={ref}>
      <Transition in={transitionState} timeout={timeout}>
        {(state) => (
          <div
            style={{
              transitionDelay: `${transitionDelay}ms`,
              transitionDuration: `${timeout}ms`,
              transitionProperty: `opacity, transform`,
              transitionTimingFunction: `cubic-bezier(0.785, 0.135, 0.15, 0.86)`,
              ...transitionStyles[state],
            }}
          >
            {props.children}
          </div>
        )}
      </Transition>
    </div>
  )
}
