import React from "react"
import { Section } from "../../models/Section"
import { AnimatedGif } from "../../models/AnimatedGif"
import { VideoSection } from ".."
import { VideoFile } from "../../models/VideoFile"

export const AnimatedGifSection = (props: AnimatedGif & Section) => {
  const { file, ...rest } = props

  const mp4Version = `${file.url}?fm=mp4`

  const videoFile: VideoFile = {
    url: mp4Version,
  }

  return <VideoSection videoTeaser={videoFile} {...rest}></VideoSection>
}
