import { useEffect, useRef } from "react"

export function usePrevious<type>(value: type, initial: type): type {
  const ref = useRef<type>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current || initial
}
