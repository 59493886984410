import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Footer, Header } from "../components"
import { AppearProvider, LayoutProvider, useLayout } from "../context"
import { Locale } from "../models/Locale"
import "../styles/designstudio.css"
import "../styles/fonts.css"
import "../styles/style.css"

interface Props {
  uri: string
  pageContext: {
    locale: Locale
  }
}

const Layout = (props: React.PropsWithChildren<Props>) => {
  return (
    <LayoutProvider locale={props.pageContext.locale}>
      <AppearProvider>
        <Body {...props} />
      </AppearProvider>
    </LayoutProvider>
  )
}

const Body = (props: React.PropsWithChildren<Props>) => {
  const { children, uri } = props
  const { isWhiteBackground } = useLayout()

  const { site } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <div
      className={classNames(
        "font-sans antialiased flex flex-col min-h-screen transition-colors ease-in-expo duration-500",
        {
          "text-black bg-white": isWhiteBackground,
          "text-white bg-black": !isWhiteBackground,
        }
      )}
    >
      <Header
        isWhiteBackground={isWhiteBackground}
        uri={uri}
        menuLinks={site.siteMetadata.menuLinks}
      />
      <main className="flex-grow pt-10 md:pt-20">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
