import classNames from "classnames"
import React from "react"
import { Appear } from ".."
import { useLayout } from "../../context"
import { Locale } from "../../models/Locale"
import { Quote } from "../../models/Quote"
import { Section } from "../../models/Section"

export const QuoteSection = (props: Quote & Section) => {
  const { content, credit, isBlog, order } = props
  const { locale } = useLayout()

  return (
    <div className="container">
      <div className="flex lg:justify-center lg:-mx-3">
        <div
          className={classNames(
            "w-full lg:px-3",
            isBlog ? "md:w-5/6 md:mx-auto lg:w-2/3 py-6 lg:py-10" : "md:w-3/4 lg:w-1/2"
          )}
        >
          <Appear order={order}>
            <blockquote
              className={classNames("heading-m", {
                "lg:heading-l": locale !== Locale.Chinese,
                "lg:text-26": locale === Locale.Chinese,
              })}
              style={{ wordBreak: "keep-all" }}
            >
              {content}
              <cite className="block paragraph-s mt-4 not-italic font-normal">{credit}</cite>
            </blockquote>
          </Appear>
        </div>
      </div>
    </div>
  )
}
