import React from "react"
import { CarouselProps, Carousel } from "./Carousel"
import { MediaFile } from "../../models/MediaFile"
import { ImageFile } from "../../models/ImageFile"
import { VideoFile } from "../../models/VideoFile"

type Props = Omit<CarouselProps, "slides"> & {
  files: MediaFile[]
}

export const MediaCarousel = (props: Props) => {
  const { files, ...rest } = props

  if (files.length === 0) {
    return null
  }

  const slides = files.map(file => {
    return {
      key: file.url,
      caption: file.title,
      image:
        !file.hasOwnProperty("video") || file.video === null
          ? (file as ImageFile)
          : undefined,
      video:
        file.hasOwnProperty("video") && file.video !== null
          ? (file as VideoFile)
          : undefined,
    }
  })

  return <Carousel slides={slides} {...rest} />
}
