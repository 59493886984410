import React, { createContext, PropsWithChildren, useMemo, useState } from "react"

interface ContextValue {
  registerTransition: (order: number) => void
  cleanTransitions: () => void
  getDelay: (order: number) => number
  time: number
}

const AppearContext = createContext<ContextValue | void>(undefined)
AppearContext.displayName = "AppearContext"

export function AppearProvider(props: PropsWithChildren<{}>) {
  const [lastUpdateTime, setLastUpdateTime] = useState(0)
  const [time, setTime] = useState(-1)
  const [transitions, setTransitions] = useState<number[]>([])
  const [timer, setTimer] = useState<NodeJS.Timeout>()

  const value: ContextValue = useMemo(() => {
    return {
      getDelay: (order: number) => {
        return transitions.indexOf(order) > 0 ? transitions.indexOf(order) : 0
      },

      registerTransition: (order: number) => {
        const timeSinceLastUpdate = new Date().getTime() - lastUpdateTime
        setLastUpdateTime(new Date().getTime())

        const newTransitions = timeSinceLastUpdate > 75 ? [] : [...transitions]

        if (newTransitions.indexOf(order) === -1) {
          newTransitions.push(order)
          newTransitions.sort((a, b) => a - b)
          setTransitions(newTransitions)
        }

        if (timer) {
          clearTimeout(timer)
        }

        setTimer(
          setTimeout(() => {
            setTime(new Date().getTime())
          }, 75)
        )
      },

      cleanTransitions: () => {
        setTransitions([])
        if (timer) {
          clearTimeout(timer)
        }
      },

      time,
    }
  }, [time, transitions, timer])

  return <AppearContext.Provider value={value} {...props} />
}

export function useAppear() {
  const context = React.useContext(AppearContext)
  if (context === undefined) {
    throw new Error(`useAppear must be used within the AppearProvider`)
  }
  return context
}
