import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { Overlay } from "../"
import { useLayout } from "../../context"
import { useWindowSize } from "../../hooks"
import { ImageFile } from "../../models/ImageFile"
import { VideoFile } from "../../models/VideoFile"
import Play from "../../svg/play.svg"
import { getFullVideo } from "../../utilities"
import "./VideoPlayerModal.css"

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  poster?: ImageFile
  video: VideoFile
}

export const VideoPlayerModal = (props: Props) => {
  const { isOpen, poster, setIsOpen, video } = props
  const [isPlaying, setIsPlaying] = useState(false)
  const [played, setIsPlayed] = useState(0)
  const { isDesktop } = useWindowSize()
  const { locale } = useLayout()

  useEffect(() => setIsPlaying(isOpen), [isOpen])

  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="container flex items-center justify-center">
        <div
          className="video-player-modal lg:relative lg:group lg:cursor-pointer"
          onClick={() => (isDesktop ? setIsPlaying(!isPlaying) : null)}
        >
          {isDesktop && !isPlaying && (
            <Play
              className="absolute group-hover:opacity-75 w-16 h-16 -ml-8 -mt-8"
              style={{ left: "50%", top: "50%" }}
            />
          )}
          <ReactPlayer
            url={video.video && getFullVideo(video, locale)}
            controls={!isDesktop}
            playing={isOpen && isPlaying}
            width="auto"
            height="auto"
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload noremoteplayback",
                  disablePictureInPicture: true,
                  poster: poster && poster.url,
                },
              },
            }}
            onProgress={(state) => setIsPlayed(state.played)}
            onEnded={() => setIsPlaying(false)}
            progressInterval={15}
          />

          {isDesktop && (
            <div
              style={{ width: `${played * 100}%` }}
              className="absolute left-0 bottom-0 bg-white h-1"
            />
          )}
        </div>
      </div>
    </Overlay>
  )
}
