import { Order } from "./Order"

export enum SectionType {
  AnimatedGif = "DatoCmsAnimatedGif",
  ClientList = "DatoCmsClientList",
  ClientTestimonial = "DatoCmsClientTestimonial",
  HeroGallery = "DatoCmsHeroGallery",
  Image = "DatoCmsImage",
  ImageGalleryLarge = "DatoCmsImageGalleryLarge",
  JobsListing = "DatoCmsJobsListing",
  StudioContact = "DatoCmsStudioContact",
  StudioList = "DatoCmsStudioList",
  Text = "DatoCmsText",
  Text2Col = "DatoCmsText2col",
  Quote = "DatoCmsQuote",
  Video = "DatoCmsVideo",
}

export type Section = Order & {
  id: string
  isBlog: boolean
  __typename: SectionType
}
