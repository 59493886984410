import classNames from "classnames"
import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import { Transition } from "react-transition-group"
import { Link, Logo, LogoTransitionType } from "../"
import { useLayout } from "../../context"
import { usePrevious, useScrollPosition } from "../../hooks"
import { MenuLink } from "../../models/MenuLink"
import { SlideDownTransition } from "../../transitions"
import { MenuMobile } from "./MenuMobile"
import "./Header.css"

interface Props {
  isWhiteBackground: boolean
  menuLinks: MenuLink[]
  uri: string
}

export const Header = (props: Props) => {
  const { isWhiteBackground, menuLinks, uri } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(true)
  const [menuVisibilityTimeout, setMenuVisibilityTimeout] = useState(400)
  const { y } = useScrollPosition()
  const previousY = usePrevious(y, 0)
  const { startLogoTransition } = useLayout()

  // URL changed - show top menu
  useEffect(() => {
    setMenuVisibilityTimeout(1000)
    setIsMenuVisible(true)
  }, [uri])

  // User scrolled - show/hide top menu
  useEffect(() => {
    setIsMenuVisible(y < previousY || y < 40)
    setMenuVisibilityTimeout(400)
  }, [y])

  return (
    <>
      <div className="header-container">
        <SlideDownTransition
          in={isMenuVisible}
          timeout={menuVisibilityTimeout}
          className="fixed z-30 top-0 inset-x-0"
        >
          <div
            className={classNames(
              "transition-colors ease-in-expo duration-500",
              {
                "text-black bg-white": isWhiteBackground,
                "text-white bg-black": !isWhiteBackground,
              }
            )}
          >
            <header className="container py-5 flex flex-wrap justify-between items-center">

              <div className="flex items-center">
                <LogoWithTransitions isWhite={!isWhiteBackground} />
                <div className="hidden md:block">
                  <motion.h2
                    className="ml-6 heading-m font-normal opacity-0"
                    variants={{
                      hidden: { opacity: 0, y: -10 },
                      visible: { opacity: 1, y: 0 },
                    }}
                    initial="hidden"
                    animate={uri === '/' ? 'visible' : 'hidden'}
                    transition={{ duration: 0.4, delay: uri === '/' ? .5 : .15, ease: [0.785, 0.135, 0.15, 0.86] }}
                  >
                    We create brands the world loves.
                  </motion.h2>
                </div>
              </div>

              <SlideDownTransition
                appear={true}
                distance="100px"
                in={true}
                timeout={400}
              >
                <button
                  className="heading-s focus:outline-none md:hidden select-none highlight-none"
                  onClick={() => setIsMenuOpen(true)}
                >
                  Menu
                </button>

                <ul className="hidden md:block">
                  {menuLinks.map((link, key) => (
                    <li key={`menuLink_${key}`} className="inline-block pl-8 ">
                      <Link
                        className="heading-s hover:opacity-70"
                        activeClassName="opacity-70"
                        partiallyActive={true}
                        to={link.to}
                        onMouseOver={() =>
                          startLogoTransition(LogoTransitionType.JumpUpAndDown)
                        }
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </SlideDownTransition>
            </header>
          </div>
        </SlideDownTransition>
      </div>
      <MenuMobile
        menuLinks={menuLinks}
        isOpen={isMenuOpen}
        setIsOpen={setIsMenuOpen}
      />
    </>
  )
}

interface LogoProps {
  isWhite?: boolean
}

const LogoWithTransitions = (props: LogoProps) => {
  const { startLogoTransition } = useLayout()

  return (
    <Transition
      in={true}
      appear={true}
      timeout={750}
      onEntering={() => startLogoTransition(LogoTransitionType.JumpInDown)}
    >
      <Logo
        isWhite={props.isWhite}
        to="/"
        onMouseOver={() =>
          startLogoTransition(LogoTransitionType.ThrowLettersUp)
        }
      />
    </Transition>
  )
}
