import React from "react"
import { Img, Link } from ".."
import { BlogItem as BlogItemModel } from "../../models/BlogItem"
import { BlogPost } from "../../models/BlogPost"
import { BlogLink } from "../../models/BlogLink"

export const BlogItem = (item: BlogItemModel) => {
  const { thumbnail, tags, title } = item

  return (
    <ClickableBlogItem item={item}>
      <div className="relative">
        <Img image={thumbnail.gatsbyImageData} alt={thumbnail.alt || ""} />
      </div>
      <div className="mt-2">
        {tags && <h6 className="paragraph-s">{JSON.parse(tags).join(", ")}</h6>}
        <h3 className="heading-s">{title}</h3>
      </div>
    </ClickableBlogItem>
  )
}

const ClickableBlogItem = (props: React.PropsWithChildren<{ item: BlogItemModel }>) => {
  const { children, item } = props

  if (item.hasOwnProperty("slug")) {
    return <Link to={`/insights/${(item as BlogPost).slug}`}>{children}</Link>
  }

  return (
    <a href={(item as BlogLink).url} target="_blank" rel="nofollow noreferrer">
      {children}
    </a>
  )
}
