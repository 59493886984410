import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Appear, StudioAddress } from ".."
import { Studio } from "../../models/Studio"
import { StudioContact } from "../../models/StudioContact"

export const StudioContactSection = (props: StudioContact) => {
  const data = useStaticQuery(graphql`
    query StudioContactSectionQuery {
      allDatoCmsStudio(
        filter: { locale: { eq: "en" } }
        sort: { fields: [position], order: ASC }
      ) {
        edges {
          node {
            ...StudioFragment
          }
        }
      }
    }
  `)

  const studios: Studio[] = data.allDatoCmsStudio.edges.map(
    (item: { node: Studio }) => item.node
  )

  return (
    <div className="container pt-8">
      <div className="flex flex-wrap md:-mx-3">
        {studios.map((studio, i) => (
          <div
            className="w-full md:w-1/2 lg:w-1/4 pb-10 md:px-3"
            key={studio.id}
          >
            <Appear order={i + 51}>
              <StudioAddress {...studio} />
            </Appear>
          </div>
        ))}
      </div>
    </div>
  )
}
