import React from "react"
import { Appear } from ".."
import { useLayout } from "../../context"
import { ClientList } from "../../models/ClientList"
import { Order } from "../../models/Order"

export const ClientListSection = (props: ClientList & Order) => {
  const { logos = [], order } = props
  const { assetLink } = useLayout()
  return (
    <div className="container -mt-8">
      <div className="flex lg:justify-center lg:-mx-3">
        <div className="w-full md:w-3/4 lg:w-1/2 lg:px-3">
          <div className="flex flex-wrap -mx-2 md:-mx-3">
            {logos.map((logo, i) => {
              return (
                <div className="w-1/2 md:w-1/3 px-2 py-3 md:px-3 md:py-4" key={`logo_${i}`}>
                  <Appear order={order + i}>
                    <img
                      src={assetLink(logo.url)}
                      alt={logo.alt}
                      style={{ filter: "invert(100%)" }}
                    />
                  </Appear>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
