import React, { useState, useRef, useEffect } from "react"
import ReactPlayer from "react-player"
import { Img, Link } from ".."
import { useWindowSize, useOnScreen } from "../../hooks"
import { CaseStudy } from "../../models/CaseStudy"
import classNames from "classnames"
import { useLayout } from "../../context"

export const CaseStudyItem = (props: CaseStudy) => {
  const { client, slug, thumbnail, thumbnailVideo, title } = props
  const { hasMouse, isMobile } = useWindowSize()
  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    setShowVideo(hasMouse && !isMobile)
  }, [])

  return (
    <Link to={`/work/${slug}`}>
      {showVideo ? (
        <VideoThumbnail thumbnailVideo={thumbnailVideo} />
      ) : (
        <Img image={thumbnail.gatsbyImageData} alt={thumbnail.alt || ""} />
      )}
      <div className="mt-4 pl-5 md:pl-0">
        <h4 className="heading-s lg:heading-m">{client}</h4>
        <h5 className="heading-s lg:heading-m font-normal">{title}</h5>
      </div>
    </Link>
  )
}

const VideoThumbnail = (props: Pick<CaseStudy, "thumbnailVideo">) => {
  const { thumbnailVideo } = props
  const [isPlaying, setIsPlaying] = useState(false)
  const [player, setPlayer] = useState<ReactPlayer>()
  const { assetLink } = useLayout()

  const ref = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(ref, "100% 0px", 0, true)

  const play = () => {
    setIsPlaying(true)
  }

  const stop = () => {
    player?.seekTo(0.034, "seconds")
    setIsPlaying(false)
  }

  return (
    <div
      onMouseEnter={play}
      onMouseLeave={stop}
      ref={ref}
      className={classNames("relative transition-opacity duration-300", { "opacity-0": !player })}
      style={{ paddingTop: "56.25%" }}
    >
      {onScreen && (
        <ReactPlayer
          url={`${assetLink(thumbnailVideo?.video?.mp4UrlMedium)}#t=0.034`}
          controls={false}
          loop={false}
          playsinline={true}
          muted={true}
          volume={0}
          onReady={(player: ReactPlayer) => setPlayer(player)}
          playing={isPlaying}
          width="100%"
          height="100%"
          className="overflow-hidden absolute inset-0"
          config={{
            file: {
              attributes: {
                className: "object-cover",
                preload: "metadata",
              },
            },
          }}
        />
      )}
    </div>
  )
}
