import React from "react"
import { Link } from ".."
import { Locale } from "../../models/Locale"

interface Props {
  locales: Locale[]
  path: string
}

export const LocaleSwitcher = (props: Props) => {
  const { locales, path } = props

  return (
    <div className="pt-6">
      {locales.length >= 2 &&
        locales.map((locale, i) => {
          return (
            <span key={i}>
              <LocaleLink locale={locale} path={path} />
              {i + 1 < locales.length && " | "}
            </span>
          )
        })}
    </div>
  )
}

interface LinkProps {
  locale: Locale
  path: string
}

const LocaleLink = (props: LinkProps) => {
  const { locale, path } = props

  let label = "English"

  switch (locale) {
    case Locale.Chinese:
      label = "中文"
      break
    case Locale.French:
      label = "Français"
      break
  }

  return (
    <Link to={path} locale={locale}>
      {label}
    </Link>
  )
}
