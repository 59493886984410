import React from "react"
// tslint:disable-next-line: no-submodule-imports
import Transition, { TransitionProps } from "react-transition-group/Transition"

export const FadeTransition = (props: TransitionProps) => {
  const { children, timeout = 250, ...rest } = props

  const transitionStyles = {
    entered: { opacity: 1 },
    entering: { opacity: 1 },
    exited: { opacity: 0 },
    exiting: { opacity: 0 },
    unmounted: { opacity: 1 },
  }

  return (
    <Transition timeout={timeout} {...rest}>
      {state => (
        <div
          style={{
            transition: `opacity ${timeout}ms cubic-bezier(0.785, 0.135, 0.15, 0.86)`,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}
