import classNames from "classnames"
import { motion } from "framer-motion"
import React, { MouseEvent, useMemo, useState } from "react"
import { useWindowSize } from "../../hooks"
import Plus from "../../svg/plus.svg"

type Props = {
  filters: string[]
  selected: string
  setSelected: (selected: string) => void
}

export const Filters = (props: Props) => {
  const { filters = [], selected, setSelected } = props
  const { isMobile } = useWindowSize()
  const [showFilters, setShowFilters] = useState(!isMobile)
  const showAll = useMemo(() => selected === filters[0], [selected])

  if (filters.length === 0) {
    return null
  }

  return (
    <>
      <div className="flex justify-between md:hidden">
        <h5
          className="inline-flex heading-s select-none items-start"
          onClick={() => setShowFilters(!showFilters)}
        >
          {showAll ? "Filter" : selected}
          <motion.span
            animate={showFilters ? "open" : "close"}
            initial="close"
            className="inline-block"
            variants={{
              close: { rotate: 0 },
              open: { rotate: 45 },
            }}
          >
            <Plus className="inline-block align-bottom w-6 h-6" />
          </motion.span>
        </h5>
        <motion.a
          className="heading-s"
          animate={showAll ? "hide" : "show"}
          initial="hide"
          variants={{
            hide: { opacity: 0, transition: { type: "tween" } },
            show: {
              opacity: 1,
              transition: { delay: 0.25, duration: 0.5, type: "tween" },
            },
          }}
          onClick={() => setSelected(filters[0])}
        >
          Clear
        </motion.a>
      </div>

      <motion.ul
        animate={showFilters ? "open" : "close"}
        initial="close"
        className={classNames("overflow-hidden", {
          "py-4 md:py-0": showFilters,
        })}
        variants={{
          close: { height: 0, opacity: 0 },
          open: {
            height: "100%",
            opacity: 1,
            transition: { duration: 0.5, type: "tween" },
          },
        }}
      >
        {filters.map(filter => (
          <Filter
            filter={filter}
            key={filter}
            isSelected={selected === filter}
            onClick={() => {
              setSelected(filter)
              if (isMobile) {
                setShowFilters(false)
              }
            }}
          />
        ))}
      </motion.ul>
    </>
  )
}

interface FilterProps {
  isSelected: boolean
  onClick: (event: MouseEvent<HTMLLIElement>) => void
  filter: string
}

const Filter = (props: FilterProps) => {
  const { filter, isSelected, onClick } = props
  return (
    <li
      className={classNames(
        "heading-s mt-3 md:mt-0 md:inline-block md:mr-6 cursor-pointer select-none",
        {
          "text-grey-mid hover:text-black": !isSelected,
          "text-black": isSelected,
        }
      )}
      onClick={event => onClick(event)}
    >
      {filter}
    </li>
  )
}
