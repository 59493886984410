import classNames from "classnames"
import React, { MouseEvent, useState } from "react"
import { Img } from "../"
import { useLayout } from "../../context"
import { ImageFile } from "../../models/ImageFile"
import { VideoFile } from "../../models/VideoFile"
import Play from "../../svg/play.svg"
import { VideoPlayerInline, VideoPlayerModal } from "../VideoPlayer"

interface Props {
  poster?: ImageFile
  videoFull?: VideoFile
  videoTeaser?: VideoFile
}

export const VideoPreview = (props: Props) => {
  const { assetLink } = useLayout()
  const poster = assetLink(props.poster)
  const videoFull = assetLink(props.videoFull)
  const videoTeaser = assetLink(props.videoTeaser)
  const [isOpen, setIsOpen] = useState(false)

  function openVideoPlayerModal(event: MouseEvent): void {
    event.preventDefault()
    setIsOpen(true)
  }

  if (videoFull) {
    return (
      <div className="bg-black">
        <a
          href={videoFull.url}
          onClick={openVideoPlayerModal}
          rel="nofollow"
          target="video"
          className="relative block"
        >
          {!videoTeaser && poster && <Img image={poster.gatsbyImageData} alt={poster.alt || ""} />}
          {videoTeaser && (
            <VideoPlayerInline video={videoTeaser} poster={poster} pauseOnClick={false} />
          )}
          <span
            className={classNames(
              "flex absolute w-full h-full inset-0 md:items-center md:justify-center group",
              {
                "items-center justify-center": !videoTeaser,
                "md:items-center md:justify-center": videoTeaser,
              }
            )}
          >
            <Play className="md:absolute cursor-pointer group-hover:opacity-75 transition-opacity duration-200 m-5 md:m-0" />
          </span>
        </a>
        <VideoPlayerModal video={videoFull} poster={poster} isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    )
  } else if (videoTeaser) {
    return <VideoPlayerInline video={videoTeaser} poster={poster} />
  }

  return <div>No video file provided</div>
}
