import React from "react"
import { Appear } from ".."
import { BlogPost } from "../../models/BlogPost"

type Props = Pick<BlogPost, "author" | "publicationDate" | "tags" | "title">

export const BlogPostHeader = (props: Props) => {
  const { author, publicationDate, tags, title } = props

  return (
    <header className="container">
      <div className="pb-20 md:w-5/6 md:mx-auto lg:pr-32 ">
        <Appear order={1}>
          <h5 className="inline-block heading-s opacity-50">Insights</h5>
          <h6 className="inline-block paragraph-s opacity-50 pl-1">
            {tags &&
              JSON.parse(tags).length > 0 &&
              " · " + JSON.parse(tags).join(", ")}
          </h6>
        </Appear>

        <Appear order={2}>
          <h1 className="heading-l lg:heading-xl mt-2 mb-4 lg:mb-6">{title}</h1>
        </Appear>

        <Appear order={3}>
          <div className="lg:paragraph-s">
            {author} -&nbsp;
            {new Date(publicationDate).toLocaleDateString()}
          </div>
        </Appear>
      </div>
    </header>
  )
}
