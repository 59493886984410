import React, { useState, useEffect } from "react"
import { CaseStudy } from "../../models/CaseStudy"
import { CaseStudyItems } from "./CaseStudyItems"
import { Country, Continent, GeoData } from "../../models/GeoData"

interface Props {
  geoData?: GeoData
  items: CaseStudy[]
  limit?: number
  search?: string
  tag?: string
}

export const CaseStudies = (props: Props) => {
  const { geoData, items, search, tag = "All Work", limit } = props
  const [filteredItems, setFilteredItems] = useState<CaseStudy[]>()

  useEffect(() => {
    setFilteredItems(applyFilters(items, tag, geoData, search))
  }, [geoData, search, tag])

  return (
    <div className="flex flex-wrap md:-mx-1">
      <CaseStudyItems items={filteredItems?.slice(0, limit)} />
    </div>
  )
}

const calculatePriorities = (geoData: GeoData) => {
  const { country_code, continent_code } = geoData

  return {
    Default: 20,
    "All visitors": 0,
    China: country_code === Country.China ? 5 : 20,
    Europe: continent_code === Continent.Europe ? 10 : 20,
    Poland: country_code === Country.Poland ? 5 : 20,
    "Asia Pacific":
      continent_code === Continent.Asia || continent_code === Continent.Oceania ? 10 : 20,
    "North America": continent_code === Continent.NorthAmerica ? 10 : 20,
    "South America": continent_code === Continent.SouthAmerica ? 10 : 20,
    India: country_code === Country.India ? 5 : 20,
  }
}

const applyFilters = (items: CaseStudy[], tag: string, geoData?: GeoData, search?: string) => {
  const priorities = geoData ? calculatePriorities(geoData) : undefined

  let filteredItems =
    tag === "All Work" || search
      ? items
      : items.filter((item) => item.tags && JSON.parse(item.tags).indexOf(tag.toLowerCase()) !== -1)

  if (search) {
    filteredItems = filteredItems.filter((item) => {
      return (
        item.client.search(new RegExp(search, "i")) !== -1 ||
        item.title.search(new RegExp(search, "i")) !== -1
      )
    })
  }

  if (!priorities) {
    return filteredItems
  }

  return filteredItems.sort((a: CaseStudy, b: CaseStudy) => {
    return priorities[a.priority] - priorities[b.priority]
  })
}
