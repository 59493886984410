import classNames from "classnames"
import React, { MouseEvent, useEffect, useRef, useState } from "react"
import { Link } from "../"
import { useLottie } from "../../hooks"
import animationData from "./animation.json"
import "./Button.css"

export interface Props {
  className?: string
  defaultIcon?: boolean
  isLarge?: boolean
  isWhite?: boolean
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  to?: string
}

export const Button = (props: React.PropsWithChildren<Props>) => {
  const {
    children,
    className = null,
    defaultIcon = true,
    isLarge = true,
    isWhite = false,
    onClick = () => void 0,
    to = "#",
  } = props
  const buttonClassName = classNames("button", { isWhite, isLarge }, className)
  const [isAnimating, setIsAnimating] = useState(false)

  const buttonParams = {
    className: buttonClassName,
    onClick,
    onMouseLeave: () => setIsAnimating(false),
    onMouseOver: () => setIsAnimating(true),
  }

  // External link
  if (!to.startsWith("/")) {
    return (
      <a href={to} target="_blank" rel="noopener" {...buttonParams}>
        {children} {defaultIcon && <Arrow isAnimating={isAnimating} />}
      </a>
    )
  }

  // Internal link
  return (
    <Link to={to} {...buttonParams}>
      {children} {defaultIcon && <Arrow isAnimating={isAnimating} />}
    </Link>
  )
}

interface ArrowProps {
  isAnimating: boolean
}

const Arrow = (props: ArrowProps) => {
  const { isAnimating } = props
  const ref = useRef<HTMLDivElement>(null)

  const [animation] = useLottie(ref, {
    animationData,
    autoplay: false,
    loop: false,
  })

  useEffect(() => {
    if (!animation) {
      return
    }
    isAnimating ? animation.play() : animation.stop()
  }, [isAnimating])

  return (
    <span className="inline-block ml-2 w-6 h-6">
      <span ref={ref} />
    </span>
  )
}
