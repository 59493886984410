import Lottie, { AnimationConfigWithData, AnimationItem } from "lottie-web"
import { RefObject, useEffect, useState } from "react"

export function useLottie(
  ref: RefObject<HTMLElement>,
  params: Omit<AnimationConfigWithData, "container">
) {
  const [animation, setAnimation] = useState<AnimationItem>()

  useEffect(() => {
    if (ref.current === null || animation !== undefined) {
      return
    }

    const a = Lottie.loadAnimation({
      container: ref.current,
      ...params,
      renderer: "svg",
    })
    setAnimation(a)
  })

  return [animation]
}
