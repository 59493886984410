import React from "react"
import { Appear, HTMLNode, Text } from ".."
import { Section } from "../../models/Section"
import { Text as TextSectionModel } from "../../models/Text"

export const TextSection = (props: TextSectionModel & Section) => {
  const { contentNode, header, isBlog, order } = props
  return (
    <Text header={header} isBlog={isBlog}>
      <Appear order={order}>
        <HTMLNode contentNode={contentNode} />
      </Appear>
    </Text>
  )
}
