import React, { useEffect, useState } from "react"
import { SwitchTransition } from "react-transition-group"
import SwiperCore, { Navigation, Pagination, SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useWindowSize } from "../../hooks"
import { Slide } from "../../models/Slide"
import { FadeTransition } from "../../transitions"
import { SlideItem } from "./SlideItem"

// tslint:disable-next-line: no-submodule-imports
import "swiper/swiper-bundle.css"
import "./Carousel.css"

SwiperCore.use([Navigation, Pagination])

export interface CarouselProps {
  caption?: string
  className?: string
  goNextOnVideoEnded?: boolean
  initialSlide?: number
  isWhite?: boolean
  onSlideChanged?: (index: number) => void
  slides: Slide[]
  swiperParams?: SwiperOptions
  withCaption?: boolean
  withNavigation?: boolean
  withOffset?: boolean
  withPagination?: boolean
}

export const Carousel = (props: CarouselProps) => {
  const {
    caption,
    className,
    goNextOnVideoEnded = false,
    initialSlide = 0,
    isWhite = false,
    onSlideChanged = (_) => void 0,
    slides,
    swiperParams = {},
    withCaption = true,
    withNavigation = true,
    withOffset = false,
    withPagination = false,
  } = props
  const [swiper, setSwiper] = useState<SwiperCore>()
  const [activeIndex, setActiveIndex] = useState(initialSlide)

  const { width } = useWindowSize(150)
  useEffect(() => setSlidesOffset(), [width])

  const setSlidesOffset = () => {
    if (withOffset && swiper) {
      swiper.params.slidesOffsetBefore = calculateContainerOffset()
      swiper.params.slidesOffsetAfter = calculateContainerOffset()
      swiper.update()
    }
  }

  const determineActiveIndex = (swiper: SwiperCore) => {
    setActiveIndex(swiper.isEnd ? swiper.slides.length - 1 : swiper.activeIndex)
  }

  useEffect(() => swiper?.on("transitionStart", () => determineActiveIndex(swiper)), [swiper])
  useEffect(() => onSlideChanged(activeIndex), [activeIndex])

  if (withNavigation) {
    swiperParams.navigation = {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }
  }

  if (withPagination) {
    swiperParams.pagination = {
      clickable: true,
      el: ".swiper-pagination",
    }
  }

  const onVideoEnded = () => {
    if (!swiper) return
    swiper.isEnd ? swiper.slideTo(0) : swiper.slideNext()
  }

  return (
    <>
      <div className={className}>
        <Swiper
          onSwiper={(swiper) => {
            setSwiper(swiper)
            setSlidesOffset()
          }}
          initialSlide={initialSlide}
          slidesPerView="auto"
          {...swiperParams}
        >
          {slides.map((slide, i) => {
            return (
              <SwiperSlide key={`slide_${slide.key}`}>
                {swiper && (
                  <SlideItem
                    image={slide.image}
                    isActive={activeIndex === i}
                    onVideoEnded={goNextOnVideoEnded ? onVideoEnded : undefined}
                    video={slide.video}
                    url={slide.url}
                  />
                )}
              </SwiperSlide>
            )
          })}
          {withNavigation && (
            <>
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
            </>
          )}
          {withPagination && <div className="swiper-pagination"></div>}
        </Swiper>
      </div>
      {withCaption && (
        <Caption defaultText={caption} index={activeIndex} isWhite={isWhite} slides={slides} />
      )}
    </>
  )
}

interface CaptionProps {
  defaultText?: string
  index: number
  isWhite: boolean
  slides: Slide[]
}

const Caption = (props: CaptionProps) => {
  const { defaultText = "", index, slides } = props

  if (!slides[index]) {
    return null
  }

  const text = slides[index].caption || defaultText
  return (
    <div className="caption container mt-2">
      <div className="flex justify-between paragraph-s">
        <SwitchTransition mode={"out-in"}>
          <FadeTransition key={text} timeout={250}>
            <div className="pr-1">{text}</div>
          </FadeTransition>
        </SwitchTransition>
        <div className="flex-shrink-0">
          {index + 1} / {slides.length}
        </div>
      </div>
    </div>
  )
}

const calculateContainerOffset = (): number => {
  const { marginLeft, paddingLeft } = window.getComputedStyle(
    document.getElementsByClassName("container")[0]
  )

  let offset = 0

  if (marginLeft) {
    offset += parseInt(marginLeft, 10)
  }

  if (paddingLeft) {
    offset += parseInt(paddingLeft, 10)
  }

  return offset
}
