import React, { createContext, PropsWithChildren, useEffect, useMemo, useState } from "react"
import { LogoTransitionType } from "../components"
import { GeoData } from "../models/GeoData"
import { Locale } from "../models/Locale"
import { getVisitorGeoData, replaceAssetsDomain } from "../utilities"

interface ContextValue {
  locale: Locale
  geoData: GeoData
  startLogoTransition: (type: LogoTransitionType) => void
  logoTransitionTime: number
  logoTransitionType: LogoTransitionType
  setIsWhiteBackground: (isWhiteBackground: boolean) => void
  isWhiteBackground: boolean
  assetLink: (object: any) => any
}
const LayoutContext = createContext<ContextValue | null>(null)
LayoutContext.displayName = "LayoutContext"

export function LayoutProvider(props: PropsWithChildren<{ locale?: Locale }>) {
  const { locale = Locale.English } = props
  const [transition, setTransition] = useState({
    time: 0,
    type: LogoTransitionType.JumpInDown,
  })
  const [isWhiteBackground, setIsWhiteBackground] = useState(true)
  const [geoData, setGeoData] = useState<GeoData>({
    country_code: "",
    continent_code: "",
  })

  useEffect(() => {
    getVisitorGeoData().then((data) => setGeoData(data))
  }, [])

  const value: ContextValue = useMemo(() => {
    return {
      assetLink: (object: any) => replaceAssetsDomain(object, locale),
      geoData,
      isWhiteBackground,
      locale,
      logoTransitionTime: transition.time,
      logoTransitionType: transition.type,
      setIsWhiteBackground,
      startLogoTransition: (type: LogoTransitionType) => {
        setTransition({
          time: new Date().getTime(),
          type,
        })
      },
    }
  }, [transition, isWhiteBackground, locale, geoData])
  return <LayoutContext.Provider value={value} {...props} />
}

export function useLayout() {
  const context = React.useContext(LayoutContext)

  if (!context) {
    throw new Error(`useLayout must be used within an LayoutProvider`)
  }

  return context
}
