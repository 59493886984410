import classNames from "classnames"
import { motion } from "framer-motion"
import React, { PropsWithChildren, useEffect } from "react"
import { useLayout } from "../../context"
import { Logo, LogoTransitionType } from "../Logo"

const backgroundVariants = {
  closed: {
    opacity: 0,
    transitionEnd: { display: "none" },
  },
  open: {
    bottom: 0,
    display: "block",
    left: 0,
    opacity: 1,
    right: 0,
    top: 0,
  },
}

const closeButtonVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
    },
  },
}

const childrenVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      delay: 0.25,
    },
  },
}

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isWhite?: boolean
}

export function Overlay(props: PropsWithChildren<Props>) {
  const { children, isOpen, isWhite = false, setIsOpen } = props
  const { startLogoTransition } = useLayout()

  function closeOnEscapeKey(event: KeyboardEvent) {
    if (event.keyCode === 27 && isOpen) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", closeOnEscapeKey)
    return () => {
      window.removeEventListener("keydown", closeOnEscapeKey)
    }
  })

  useEffect(() => {
    document
      .querySelectorAll("body, html")
      .forEach(e => e.classList[isOpen ? "add" : "remove"]("overflow-hidden"))

    if (isOpen) {
      startLogoTransition(LogoTransitionType.JumpInDown)
    }
  }, [isOpen])

  return (
    <motion.div
      initial="closed"
      className={classNames(
        "fixed z-50 block",
        isWhite ? "bg-white" : "bg-black"
      )}
      animate={isOpen ? "open" : "closed"}
      variants={backgroundVariants}
    >
      <div className="flex flex-col h-full max-h-full">
        <div className="container">
          <div className="flex justify-between items-center py-5">
            <Logo
              isWhite={!isWhite}
              withTimer={false}
              to="/"
              onClick={() => setIsOpen(false)}
            />

            <motion.button
              initial="closed"
              animate={isOpen ? "open" : "closed"}
              variants={closeButtonVariants}
              className={classNames(
                "heading-s hover:opacity-75 focus:outline-none select-none highlight-none",
                isWhite ? "text-black" : "text-white"
              )}
              onClick={() => setIsOpen(false)}
            >
              Close
            </motion.button>
          </div>
        </div>
        <motion.div
          className="flex flex-grow overflow-hidden"
          animate={isOpen ? "open" : "closed"}
          variants={childrenVariants}
        >
          {children}
        </motion.div>
      </div>
    </motion.div>
  )
}
