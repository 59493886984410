import React, { createElement } from "react"
import rehypeReact from "rehype-react"
import unified from "unified"
import { Button, HTMLText } from "../"
import { HTMLNode as HTMLNodeModel } from "../../models/HTMLNode"

interface Props {
  contentNode: HTMLNodeModel
}

const processor = unified().use(rehypeReact, {
  createElement,
  components: {
    button: (props: any) => <Button {...props} />,
  },
})

const renderAst = (ast: any): JSX.Element => {
  return (processor.stringify(ast) as unknown) as JSX.Element
}

export const HTMLNode = (props: Props) => {
  const {
    contentNode: {
      childMarkdownRemark: { htmlAst },
    },
  } = props
  return <HTMLText>{renderAst(htmlAst)}</HTMLText>
}
