import React from "react"
import Linkedin from "../../svg/linkedin.svg"
import Twitter from "../../svg/twitter.svg"

interface Props {
  title: string
  url: string
}

export const Share = (props: Props) => {
  const { title, url } = props

  return (
    <div className="paragraph-s md:pl-3">
      Share
      <ul className="inline-block align-middle">
        <li className="inline-block ml-6">
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
              url
            )}&title=${encodeURI(title)}`}
            target="_blank"
            rel="noopener"
            className="inline-block hover:opacity-70"
            aria-label="LinkedIn"
          >
            <Linkedin className="w-6 h-6 fill-current" />
          </a>
        </li>
        <li className="inline-block ml-6">
          <a
            href={`https://twitter.com/intent/tweet?text=${encodeURI(
              `${title} ${url}`
            )}`}
            target="_blank"
            rel="noopener"
            className="inline-block hover:opacity-70"
            aria-label="Twitter"
          >
            <Twitter className="w-6 h-6 fill-current" />
          </a>
        </li>
      </ul>
    </div>
  )
}
