import { motion } from "framer-motion"
import React from "react"
import { Appear } from "../"
import { CaseStudy } from "../../models/CaseStudy"
import { CaseStudyItem } from "./CaseStudyItem"

interface Props {
  items: CaseStudy[]
}
export const CaseStudyItems: any = (props: Props) => {
  const { items } = props

  if (!items || items.length === 0) {
    return null
  }

  return items.map((item, key) => (
    <motion.div
      key={item.id}
      className={`w-full mb-12 md:w-1/2 md:px-1`}
      layout
      transition={{ type: "tween" }}
    >
      <Appear order={5 + key}>
        <CaseStudyItem {...item} />
      </Appear>
    </motion.div>
  ))
}
