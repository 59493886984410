import React from "react"
import { Appear } from ".."
import { BlogPost } from "../../models/BlogPost"
import { Share } from "../Share"

type Props = Pick<BlogPost, "author" | "publicationDate" | "slug" | "title">

export const BlogPostFooter = (props: Props) => {
  const { author, publicationDate, slug, title } = props

  return (
    <footer className="container">
      <Appear order={5}>
        <div className="flex md:justify-center lg:-mx-3">
          <div className="w-full md:w-5/6 lg:w-2/3 lg:px-3">
            <div className="flex flex-wrap justify-between mt-4 mb-10 py-6 lg:py-10 border-t border-b border-black-border">
              <div className="w-full md:w-auto paragraph-s md:pr-3 mb-4 md:mb-0">
                By {author} -&nbsp;
                {new Date(publicationDate).toLocaleDateString()}
              </div>
              <Share
                title={title}
                url={`https://design.studio/insights/${slug}`}
              />
            </div>
          </div>
        </div>
      </Appear>
    </footer>
  )
}
