import React from "react"
import { MediaFile } from "../../models/MediaFile"
import { MediaCarousel } from ".."
import { Overlay } from "../Overlay"

interface Props {
  caption?: string
  onSlideChanged?: (index: number) => void
  initialSlide?: number
  isOpen: boolean
  photos: MediaFile[]
  setIsOpen: (isOpen: boolean) => void
}

export const ImageGalleryModal = (props: Props) => {
  const {
    caption,
    onSlideChanged,
    initialSlide,
    isOpen,
    photos,
    setIsOpen,
  } = props

  const swiperParams = {
    breakpoints: {
      768: {
        spaceBetween: 24,
      },
    },
    keyboard: {
      enabled: true,
    },
    spaceBetween: 10,
  }

  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen} isWhite={true}>
      {isOpen && (
        <div className="w-full flex flex-col justify-center pb-5">
          <MediaCarousel
            caption={caption}
            className="max-w-792 isWhite caption-bottom"
            files={photos}
            initialSlide={initialSlide}
            isWhite={true}
            onSlideChanged={onSlideChanged}
            swiperParams={swiperParams}
            withOffset={true}
          />
        </div>
      )}
    </Overlay>
  )
}
