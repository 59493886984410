import classNames from "classnames"
import React, { PropsWithChildren } from "react"
import { Appear } from ".."

interface Props {
  header?: string
  isBlog?: boolean
}

export const Text = (props: PropsWithChildren<Props>) => {
  const { children, header, isBlog = false } = props
  return (
    <div className="container">
      <div
        className={classNames("flex flex-wrap lg:-mx-3", {
          "lg:justify-center": !header,
          "md:justify-center": isBlog,
        })}
      >
        <Header header={header} isBlog={isBlog} />

        <div
          className={classNames(
            isBlog ? "md:w-5/6 lg:w-2/3" : "md:w-3/4 lg:w-1/2",
            "paragraph-s lg:paragraph-m lg:px-3"
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

const Header = (props: Pick<Props, "header" | "isBlog">) => {
  const { header, isBlog = false } = props

  if (!header) {
    return null
  }

  if (isBlog) {
    return (
      <div className="w-full md:w-5/6 lg:w-2/3 lg:px-3">
        <Appear order={5}>
          <h3 className="heading-s lg:heading-m mb-4 lg:mb-6">{header}</h3>
        </Appear>
      </div>
    )
  }

  return (
    <div className="w-full lg:w-1/4 lg:px-3">
      <Appear order={5}>
        <h3 className="mb-2 heading-s">{header}</h3>
      </Appear>
    </div>
  )
}
