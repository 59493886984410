import React from "react"
import { Appear, HTMLNode, Text } from ".."
import { JobsListing } from "../../models/JobsListing"
import { Order } from "../../models/Order"
import { JobOffers } from "./JobOffers"

export const JobsListingSection = (props: JobsListing & Order) => {
  const { introNode, order, outroNode, title } = props

  return (
    <div className="bg-grey-light pt-10 pb-20 lg:pt-20">
      <div className="container">
        <Appear order={order}>
          <h1 className="heading-l lg:heading-xl mb-6 lg:mb-20">{title}</h1>
        </Appear>
      </div>

      <Text>
        <Appear order={order + 1}>
          <HTMLNode contentNode={introNode} />
        </Appear>
      </Text>

      <Appear order={order + 2}>
        <div className="container">
          <div className="flex lg:justify-center lg:-mx-3">
            <div className="w-full md:w-3/4 lg:w-1/2 lg:px-3">
              <JobOffers />
            </div>
          </div>
        </div>
      </Appear>

      <Text>
        <Appear order={order + 3}>
          <HTMLNode contentNode={outroNode} />
        </Appear>
      </Text>
    </div>
  )
}
