import React from "react"
import { Appear, Text, Img } from ".."
import { PressLink } from "../../models/PressLink"
import { Order } from "../../models/Order"
import classNames from "classnames"
interface Props {
  isBlog?: boolean
  links: PressLink[]
}

export const PressLinks = (props: Props) => {
  const { isBlog = false, links } = props
  return (
    <Text isBlog={isBlog} header="Press">
      {links.map((link, i) => (
        <PressLinkItem key={link.url} order={i + 50} isBlog={isBlog} {...link} />
      ))}
    </Text>
  )
}

const PressLinkItem = (link: PressLink & Order & { isBlog: boolean }) => {
  const { date, image, isBlog, media, title, url, order } = link
  return (
    <div className="pb-4 lg:pb-8 last:pb-0">
      <Appear key={link.url} order={order}>
        <div className="flex">
          <div className="pr-4 lg:pr-8">
            <Img image={image.gatsbyImageData} alt={image.alt || ""} />
          </div>
          <div>
            <h5 className="paragraph-s">{media}</h5>
            <a
              href={url}
              target="_blank"
              rel="nofollow noreferrer"
              className={classNames(
                "paragraph-s inline-block leading-24 underline hover:opacity-70",
                { "max-w-sm": !isBlog }
              )}
            >
              {title}
            </a>
            <div className="paragraph-s">{new Date(date).toLocaleDateString()}</div>
          </div>
        </div>
      </Appear>
    </div>
  )
}
