import React from "react"
import ReactPlayer from "react-player"
import { useLayout } from "../../context"
import { ImageFile } from "../../models/ImageFile"
import { VideoFile } from "../../models/VideoFile"
import { getFullVideo } from "../../utilities"

interface SlideItemVideoProps {
  image?: ImageFile
  isPlaying: boolean
  onEnded?: () => void
  video: VideoFile
}

export const SlideItemVideo = (props: SlideItemVideoProps) => {
  const { image, isPlaying, onEnded, video } = props
  const { locale } = useLayout()

  return (
    <ReactPlayer
      key={video.url}
      url={getFullVideo(video, locale)}
      controls={false}
      loop={onEnded ? false : true}
      onEnded={onEnded}
      playsinline={true}
      muted={true}
      playing={isPlaying}
      width="100%"
      height="auto"
      style={{ maxWidth: "1560px", textAlign: "center" }}
      config={{
        file: {
          attributes: {
            className: "object-cover",
            disablePictureInPicture: true,
            poster: image ? image.url : video.video && video.video.thumbnailUrl,
          },
        },
      }}
    />
  )
}
