import React from "react"
import { Appear } from ".."
import { ClientTestimonial, Testimonial } from "../../models/ClientTestimonial"
import { Order } from "../../models/Order"
import { VideoPreview } from "../VideoPreview"

export const ClientTestimonialSection = (props: ClientTestimonial & Order) => {
  const { header, testimonials = [], order } = props
  return (
    <div className="container">
      <Appear order={order}>
        <h2 className="heading-s mb-10">{header}</h2>
      </Appear>
      <div className="flex flex-wrap md:-mx-3">
        {testimonials.map((testimonial, i) => (
          <div
            className="w-full md:w-1/2 lg:w-1/4 pb-10 md:px-3"
            key={`testimonial_${i}`}
          >
            <Appear order={i + order + 2}>
              <TestimonialItem {...testimonial} />
            </Appear>
          </div>
        ))}
      </div>
    </div>
  )
}

const TestimonialItem = (props: Testimonial) => {
  const { name, positionCompany, thumbnail, title, video } = props
  return (
    <>
      <VideoPreview videoFull={video} poster={thumbnail} />
      <h3 className="heading-m my-4">{title}</h3>
      <h5 className="heading-s">
        {name}
        <br />
        {positionCompany}
      </h5>
    </>
  )
}
