import React, { useState } from "react"
import { Carousel } from ".."
import { HomeSlide } from "../../models/HomeSlide"
import { Slide } from "../../models/Slide"
import { SwitchTransition } from "react-transition-group"
import { FadeTransition } from "../../transitions"
import { Button } from "../Button"

interface Props {
  homeSlides: HomeSlide[]
}

export const HomeSlider = (props: Props) => {
  const { homeSlides } = props

  if (homeSlides.length === 0) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState<HomeSlide>(homeSlides[0])

  const slides: Slide[] = homeSlides.map((slide, key) => {
    return {
      key: `homeSlide_${key}`,
      image: slide.image,
      video: slide.video,
      url: slide.url,
    }
  })

  return (
    <div className="md:container">
      <div className="md:flex md:items-end md:space-x-6 lg:space-x-10">
        <div className="w-full md:w-2/3 lg:w-3/4">
          <Carousel
            goNextOnVideoEnded={true}
            onSlideChanged={(index) => setCurrentSlide(homeSlides[index])}
            slides={slides}
            withCaption={false}
            withNavigation={false}
            withPagination={true}
          />
        </div>
        <div className="w-full md:w-1/3 lg:w-1/4 px-5 md:px-0">
          <SwitchTransition mode={"out-in"}>
            <FadeTransition key={`homeslide_summary_${currentSlide.summary}`} timeout={400}>
              <h2 className="heading-m lg:heading-l font-semibold whitespace-pre-line pr-2 mt-5 mb-4 md:mb-8">
                {currentSlide.summary}
              </h2>
              <Button to={currentSlide.url}>See the results</Button>
            </FadeTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  )
}
