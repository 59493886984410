import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player"
import { useLayout } from "../../context"
import { useOnScreen } from "../../hooks"
import { ImageFile } from "../../models/ImageFile"
import { VideoFile } from "../../models/VideoFile"
import Play from "../../svg/play.svg"
import { getFullVideo } from "../../utilities"

interface Props {
  pauseOnClick?: boolean
  poster?: ImageFile
  video: VideoFile
}

export const VideoPlayerInline = (props: Props) => {
  const { pauseOnClick = true, poster, video } = props
  const [isPlaying, setIsPlaying] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(ref, "-100px 0px -50px")
  const { locale } = useLayout()

  useEffect(() => setIsPlaying(onScreen), [onScreen])

  return (
    <div
      className={classNames("relative flex items-center justify-center group", {
        "cursor-pointer": pauseOnClick,
      })}
      ref={ref}
      onClick={() => {
        if (pauseOnClick === true) {
          setIsPlaying(!isPlaying)
        }
      }}
    >
      <Play
        className={classNames(
          "pointer-events-none absolute group-hover:opacity-75 transition-opacity duration-150",
          {
            hidden: isPlaying === true,
            block: isPlaying === false,
          }
        )}
      />
      <ReactPlayer
        url={video.size && video.size < 1500000 ? video.url : getFullVideo(video, locale)}
        controls={false}
        loop={true}
        playsinline={true}
        muted={true}
        playing={isPlaying}
        width="100%"
        height="auto"
        style={{ maxWidth: "1560px", textAlign: "center" }}
        config={{
          file: {
            attributes: {
              className: "object-cover",
              disablePictureInPicture: true,
              poster: poster && poster.url,
            },
          },
        }}
      />
    </div>
  )
}
