import classNames from "classnames"
import React from "react"
import { SectionType } from "../../models/Section"
import {
  AnimatedGifSection,
  ClientListSection,
  ClientTestimonialSection,
  HeroGallerySection,
  ImageSection,
  ImageGalleryLargeSection,
  JobsListingSection,
  StudioContactSection,
  StudioListSection,
  QuoteSection,
  TextSection,
  Text2colSection,
  VideoSection,
} from ".."

export const Section = props => {
  const { isBlog = false, section, order = 5 } = props

  return (
    <div className={classNames(isBlog ? "pb-10" : "pb-16 lg:pb-20")}>
      <SectionComponent
        isBlog={isBlog}
        order={5 + order * 3}
        section={section}
      />
    </div>
  )
}

const SectionComponent = ({ section, ...params }) => {
  switch (section.__typename) {
    case SectionType.AnimatedGif:
      return <AnimatedGifSection {...section} {...params} />
    case SectionType.ClientList:
      return <ClientListSection {...section} {...params} />
    case SectionType.ClientTestimonial:
      return <ClientTestimonialSection {...section} {...params} />
    case SectionType.HeroGallery:
      return <HeroGallerySection {...section} {...params} />
    case SectionType.Image:
      return <ImageSection {...section} {...params} />
    case SectionType.ImageGalleryLarge:
      return <ImageGalleryLargeSection {...section} {...params} />
    case SectionType.JobsListing:
      return <JobsListingSection {...section} {...params} />
    case SectionType.StudioContact:
      return <StudioContactSection {...section} {...params} />
    case SectionType.StudioList:
      return <StudioListSection {...section} {...params} />
    case SectionType.Text:
      return <TextSection {...section} {...params} />
    case SectionType.Text2Col:
      return <Text2colSection {...section} {...params} />
    case SectionType.Quote:
      return <QuoteSection {...section} {...params} />
    case SectionType.Video:
      return <VideoSection {...section} {...params} />
    default:
      return <div className="container">{section.__typename}</div>
  }
}
