import React from "react"
import { Appear, MediaCarousel } from "../"
import { ImageGalleryLarge } from "../../models/ImageGalleryLarge"
import { Order } from "../../models/Order"

export const ImageGalleryLargeSection = (props: ImageGalleryLarge & Order) => {
  const { caption, files, order } = props

  const swiperParams = {
    breakpoints: {
      768: {
        spaceBetween: 24,
      },
    },
    spaceBetween: 10,
  }

  if (files.length === 0) {
    return null
  }

  return (
    <Appear order={order}>
      <MediaCarousel
        caption={caption}
        className="max-w-792"
        withOffset={true}
        files={files}
        swiperParams={swiperParams}
      />
    </Appear>
  )
}
