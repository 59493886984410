import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Appear, StudioDescription } from ".."
import { Studio } from "../../models/Studio"
import { StudioList } from "../../models/StudioList"

export const StudioListSection = (props: StudioList) => {
  const { title } = props

  const data = useStaticQuery(graphql`
    query StudioListQuery {
      allDatoCmsStudio(
        filter: { locale: { eq: "en" } }
        sort: { fields: [position], order: ASC }
      ) {
        edges {
          node {
            ...StudioFragment
          }
        }
      }
    }
  `)

  const studios: Studio[] = data.allDatoCmsStudio.edges.map(
    (item: { node: Studio }) => item.node
  )

  return (
    <div className="container pb-10">
      <Appear order={50}>
        <h1 className="heading-l lg:heading-xl">{title}</h1>
      </Appear>
      <div className="flex flex-wrap md:-mx-3 md:-mt-4">
        {studios.map((studio, i) => (
          <div
            className="w-full pt-10 md:w-1/2 md:pt-20 md:px-3"
            key={`studio_${i}`}
          >
            <Appear order={i + 51}>
              <StudioDescription studio={studio} />
            </Appear>
          </div>
        ))}
      </div>
    </div>
  )
}
