import React from "react"
import { Appear, BlogItem } from ".."
import { BlogPost } from "../../models/BlogPost"

interface Props {
  posts?: BlogPost[]
}

export const RelatedBlogPosts = (props: Props) => {
  const { posts = [] } = props

  if (posts.length === 0) {
    return null
  }

  return (
    <section className="container mt-4 lg:mt-0 pb-8 lg:pb-12">
      <h3 className="heading-m mb-6 md:mt-10 md:mb-8">
        <Appear order={50}>Read more</Appear>
      </h3>

      <div className="flex flex-wrap md:-mx-3">
        {posts.map((item, i) => (
          <div key={item.id} className="w-full md:w-1/3 md:px-3 mb-8">
            <Appear order={i + 51}>
              <BlogItem key={item.id} {...item} />
            </Appear>
          </div>
        ))}
      </div>
    </section>
  )
}
