import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Appear } from ".."
import { Link } from "../"
import { useLayout } from "../../context"
import { JobOffer } from "../../models/JobOffer"
import { groupBy } from "../../utilities"

export const JobOffers = () => {
  const { locale } = useLayout()

  const data = useStaticQuery(graphql`
    query JobOffersQuery {
      allDatoCmsJobOffer(sort: { fields: position, order: ASC }) {
        nodes {
          id
          locale
          studio {
            city
          }
          slug
          title
          position
        }
      }
    }
  `)

  const jobOffers = data.allDatoCmsJobOffer.nodes.filter((n: JobOffer) => n.locale == locale)
  const cities = groupBy<JobOffer>(jobOffers, (job) => job.studio.city)

  return (
    <div className="mb-6 mt-12 lg:mb-12">
      {Object.keys(cities).map((city, i) => (
        <Appear order={10 + i} key={city}>
          <JobsCity city={city} jobs={cities[city]} />
        </Appear>
      ))}
    </div>
  )
}

interface JobsCityProps {
  city: string
  jobs: JobOffer[]
}

const JobsCity = (props: JobsCityProps) => {
  const { city, jobs } = props
  return (
    <div className="pb-6">
      <h3 className="heading-m lg:heading-l mb-4 lg:mb-6">{city}</h3>
      <ul className="grid md:grid-cols-3 gap-4">
        {jobs.map((job) => {
          return (
            <li key={job.id}>
              <Link
                className="paragraph-s lg:paragraph-m lg:border-b hover:opacity-70"
                to={`/join/${job.slug}`}
              >
                {job.title}
              </Link>
            </li>
          )
        })}
      </ul>
      <hr className="bg-black opacity-10 mt-4" />
    </div>
  )
}
