import classNames from "classnames"
import React, { PropsWithChildren } from "react"
import { Appear, VideoPreview } from ".."
import { Section } from "../../models/Section"
import { Video } from "../../models/Video"

type Props = Video & Section

export const VideoSection = (props: Props) => {
  const {
    caption,
    fullWidth = false,
    isBlog = false,
    poster,
    videoFull,
    videoTeaser,
    order,
  } = props

  return (
    <Container
      fullWidth={fullWidth}
      isBlog={isBlog}
      order={order}
      caption={caption}
    >
      <VideoPreview
        poster={poster}
        videoFull={videoFull}
        videoTeaser={videoTeaser}
      />
    </Container>
  )
}

type ContainerProps = Pick<Props, "caption" | "fullWidth" | "isBlog" | "order">

const Container = (props: PropsWithChildren<ContainerProps>) => {
  const { caption, children, fullWidth, isBlog, order } = props

  if (fullWidth) {
    return (
      <div style={{ maxWidth: "1560px" }} className="mx-auto">
        <Appear order={order}>{children}</Appear>
        {caption && <div className="container paragraph-s mt-2">{caption}</div>}
      </div>
    )
  }

  return (
    <div className="container">
      <div className="flex justify-center lg:-mx-3">
        <div
          className={classNames(
            "w-full lg:px-3",
            isBlog ? "md:w-5/6 lg:w-2/3" : "lg:w-1/2"
          )}
        >
          <Appear order={order}>{children}</Appear>
          {caption && <div className="paragraph-s mt-2">{caption}</div>}
        </div>
      </div>
    </div>
  )
}
