import React from "react"
import { Transition } from "react-transition-group"
// tslint:disable-next-line: no-submodule-imports
import { TransitionProps } from "react-transition-group/Transition"

type Props = TransitionProps & {
  distance?: string
}

export const SlideDownTransition = (props: Props) => {
  const {
    children,
    className,
    distance = "100%",
    timeout = 250,
    ...rest
  } = props

  const transitionStyles = {
    entered: { transform: `translateY(0)` },
    entering: { transform: `translateY(0)` },
    exited: { transform: `translateY(-${distance})` },
    exiting: { transform: `translateY(-${distance})` },
    unmounted: { transform: `translateY(0)` },
  }

  return (
    <Transition timeout={timeout} {...rest}>
      {state => (
        <div
          className={className}
          style={{
            transition: `transform ${timeout}ms cubic-bezier(0.785, 0.135, 0.15, 0.86)`,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}
