import React from "react"
import { Appear, MediaCarousel } from "../../components"
import { HeroGallery } from "../../models/HeroGallery"
import { Order } from "../../models/Order"

export const HeroGallerySection = (props: HeroGallery & Order) => {
  const { caption, files, order } = props
  return (
    <Appear order={order}>
      <div style={{ maxWidth: "1560px" }} className="mx-auto">
        <MediaCarousel caption={caption} files={files} />
      </div>
    </Appear>
  )
}
