import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { useLayout } from "../../context"

export const SEO = (props) => {
  let { seoMetaTags = null, title = null } = props
  const { locale, assetLink } = useLayout()
  const { allDatoCmsSite } = useStaticQuery(graphql`
    query SEOQuery {
      allDatoCmsSite {
        nodes {
          locale
          noIndex
          faviconMetaTags {
            tags
          }
          globalSeo {
            fallbackSeo {
              title
              description
              twitterCard
              image {
                url
              }
            }
            titleSuffix
            twitterAccount
          }
        }
      }
    }
  `)

  const site = allDatoCmsSite.nodes.find((s) => s.locale === locale)

  let meta = []

  if (seoMetaTags) {
    title = seoMetaTags.tags.filter((tag) => tag.tagName === "title").map((tag) => tag.content)[0]

    meta = seoMetaTags.tags.filter((tag) => tag.tagName === "meta").map((tag) => tag.attributes)
  }

  if (!title) {
    title = `${site.globalSeo.fallbackSeo.title}${site.globalSeo.titleSuffix}`
  }

  if (meta.length === 0) {
    meta = [
      {
        content: site.noIndex === true ? "noindex" : "all",
        name: "robots",
      },
      {
        content: site.globalSeo.fallbackSeo.description,
        name: `description`,
      },
      {
        content: title,
        property: `og:title`,
      },
      {
        content: site.globalSeo.fallbackSeo.description,
        property: `og:description`,
      },
      {
        content: `website`,
        property: `og:type`,
      },
      {
        content: site.globalSeo.fallbackSeo.twitterCard,
        name: `twitter:card`,
      },
      {
        content: site.globalSeo.twitterAccount,
        name: `twitter:creator`,
      },
      {
        content: title,
        name: `twitter:title`,
      },
      {
        content: site.globalSeo.fallbackSeo.description,
        name: `twitter:description`,
      },
      {
        content: site.globalSeo.fallbackSeo.image?.url,
        name: "og:image",
      },
      {
        content: site.globalSeo.fallbackSeo.image?.url,
        name: "twitter:image",
      },
    ]
  }

  meta = assetLink(meta)

  return <Helmet htmlAttributes={{ lang: locale }} title={title} meta={meta} />
}
