import classNames from "classnames"
import { GatsbyLinkProps } from "gatsby"
import React, { useEffect, useRef } from "react"
import IdleTimer from "react-idle-timer"
import { getSegment, LogoTransitionType } from "."
import { Link } from "../"
import { useLayout } from "../../context"
import { useLottie } from "../../hooks/useLottie"
import animationData from "./animation.json"
import "./Logo.css"

interface Props {
  isWhite?: boolean
  withTimer?: boolean
}

export const Logo = (props: Props & GatsbyLinkProps<true>) => {
  const { isWhite = false, withTimer = true, ...linkProps } = props
  const { logoTransitionTime, logoTransitionType } = useLayout()
  const ref = useRef<HTMLDivElement>(null)

  let idleTimer: IdleTimer | null

  const [animation] = useLottie(ref, {
    animationData,
    autoplay: false,
    loop: false,
  })

  // Start animation when last interaction time has changed
  useEffect(() => {
    if (!animation) {
      return
    }
    animation.playSegments(getSegment(logoTransitionType), true)
  }, [logoTransitionTime])

  const renderLogo = () => {
    return (
      <h1 className="inline-block w-10 h-10 relative">
        <div ref={ref} className={classNames("logo", { isWhite })} />
        <Link
          className="absolute inset-0 overflow-hidden"
          style={{ color: "transparent" }}
          onMouseOver={() =>
            animation?.playSegments(
              getSegment(LogoTransitionType.JumpUpAndDown),
              true
            )
          }
          {...linkProps}
        >
          DesignStudio
        </Link>
      </h1>
    )
  }

  if (withTimer === false) {
    return renderLogo()
  }

  return (
    <IdleTimer
      onIdle={() => {
        if (idleTimer) {
          idleTimer.reset()
        }
        animation?.playSegments(getSegment(LogoTransitionType.Wiggle), true)
      }}
      ref={idleTimerRef => {
        idleTimer = idleTimerRef
      }}
      timeout={6 * 1000}
    >
      {renderLogo()}
    </IdleTimer>
  )
}
