import React from "react"
import { Studio } from "../../models/Studio"
import { Button, Img } from ".."

export const StudioAddress = (props: Studio) => {
  const { address, country, email, phone, region } = props
  return (
    <div className="paragraph-s">
      <h3 className="mb-4 lg:mb-6 heading-m">{region}</h3>
      {/* <Img fluid={photos[0].fluid} alt={photos[0].alt} /> */}

      <Button to={`mailto:${email}`} className="mt-6 mb-8" isLarge={false}>
        Contact {region}
      </Button>

      <address className="mt-2 not-italic whitespace-pre-line">{address}</address>
      <div>{country}</div>

      <div className="my-6">
        <a href={`tel:${phone}`}>{phone}</a>
      </div>

      <h5 className="heading-s">New Business</h5>
      <a href={`mailto:${email}`} className="underline hover:opacity-75">
        {email}
      </a>
    </div>
  )
}
