import classNames from "classnames"
import React from "react"
import { Appear, HTMLNode } from ".."
import { Section } from "../../models/Section"
import { Text2col } from "../../models/Text2col"
import "./Text2colSection.css"

export const Text2colSection = (props: Text2col & Section) => {
  const { columnLeftNode, columnRightNode, header, isBlog, order } = props
  return (
    <div className="container">
      <div
        className={classNames("flex flex-wrap lg:-mx-3", {
          "lg:justify-center": !header,
          "md:justify-center": isBlog,
        })}
      >
        <Header header={header} isBlog={isBlog} />

        <div
          className={classNames(
            "flex flex-wrap w-full paragraph-s lg:px-3",
            isBlog ? "md:w-5/6 lg:w-2/3" : "md:w-3/4 lg:w-1/2 "
          )}
        >
          <div className="w-full md:w-1/2 lg:pr-3 text2col-left">
            <Appear order={order + 1}>
              <HTMLNode contentNode={columnLeftNode} />
            </Appear>
          </div>

          <div className="w-full md:w-1/2 lg:pl-3 text2col-right">
            <Appear order={order + 2}>
              <HTMLNode contentNode={columnRightNode} />
            </Appear>
          </div>
        </div>
      </div>
    </div>
  )
}

const Header = (props: Pick<Text2col, "header" | "isBlog">) => {
  const { header, isBlog = false } = props

  if (!header) {
    return null
  }

  if (isBlog) {
    return (
      <div className="w-full md:w-5/6 lg:w-2/3 lg:px-3">
        <Appear order={5}>
          <h3 className="heading-s lg:heading-m mb-4 lg:mb-6">{header}</h3>
        </Appear>
      </div>
    )
  }

  return (
    <div className="w-full lg:w-1/4 lg:px-3">
      <Appear order={5}>
        <h3 className="mb-2 heading-s">{header}</h3>
      </Appear>
    </div>
  )
}
