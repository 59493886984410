import React from "react"
import { Appear } from "../"
import { Studio } from "../../models/Studio"
import { useStaticQuery, graphql } from "gatsby"

export const FooterStudios = () => {
  const data = useStaticQuery(graphql`
    query FooterStudiosQuery {
      allDatoCmsStudio(
        filter: { locale: { eq: "en" } }
        sort: { fields: [position], order: ASC }
      ) {
        edges {
          node {
            ...StudioFragment
          }
        }
      }
    }
  `)

  const studios: Studio[] = data.allDatoCmsStudio.edges.map(
    (item: { node: Studio }) => item.node
  )

  return (
    <div className="container pt-6 lg:pt-12">
      <div className="flex flex-wrap md:-mx-3 justify-between">
        {studios.map((studio, i) => (
          <div
            className="w-full md:w-1/2 lg:w-auto pb-10 md:px-3"
            key={studio.id}
          >
            <Appear order={i + 91}>
              <FooterStudio {...studio} />
            </Appear>
          </div>
        ))}
      </div>
    </div>
  )
}

const FooterStudio = (props: Studio) => {
  const { address, email, phone, region } = props
  return (
    <div className="paragraph-s">
      <h3 className="heading-s">{region}</h3>
      <address className="not-italic whitespace-pre-line">{address}</address>
      <div className="mt-2 mb-2 lg:mt-4 lg:mb-0">
        <a href={`mailto:${email}`} className="underline hover:opacity-75">
          {email}
        </a>
      </div>
      <div>
        <a href={`tel:${phone}`}>{phone}</a>
      </div>
    </div>
  )
}
