import { RefObject, useEffect, useState } from "react"

export function useOnScreen(
  ref: RefObject<HTMLElement>,
  rootMargin = "0px",
  threshold = 0,
  stopAfterAppear = false
): boolean {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)

        // Remove observer if element become visible and this is all we wanted to check
        if (stopAfterAppear && entry.isIntersecting && ref.current) {
          observer.unobserve(ref.current)
        }
      },
      {
        rootMargin,
        threshold,
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}
